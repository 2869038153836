import React, { useState, useEffect } from 'react';
import GradientBackground from './GradientBackground';
import './App.css';
import { useGlitchEffect } from './glitchHover'; // Make sure the path is correct
import image2 from './image2.webp';
import RollingText from './RollingText'; // Import the new component


function App() {
  const [scrollY, setScrollY] = useState(0);
  const [glitchText, onMouseEnter, onMouseLeave] = useGlitchEffect('Tpov');
  // State to manage image visibility
  const [isInView] = useState(false);


  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const section2Start = window.innerHeight * 0.7; // Adjust as needed



  const textStyle = {
    position: 'fixed', // Keeps the text in the viewport
    left: '50%', // Center align horizontally
    top: '30%', // Center align vertically
    fontSize: scrollY > 100 ? `${2 + scrollY / 100}rem` : '12rem', // Adjust font size
    transform: `translate(-50%, -50%) scale(${scrollY > 100 ? 1 + scrollY / 1000 : 1})`,
    opacity: scrollY > section2Start ? '0' : '1', // Fade out as we reach Section 2
    transition: 'opacity 0.5s, transform 0.5s, font-size 0.5s',
  };
  const downArrowSVG = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 19L5 12H9V5H15V12H19L12 19Z" fill="currentColor"/>
    </svg>
  );

  return (
    <div className="App">
      <GradientBackground />
      <RollingText /> {/* Add the RollingText component */}

      <div className="section">
        {/* Existing glitch text */}
        <h1 onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={textStyle}>
          {glitchText}
        </h1>
        {/* New text element using h2 tag */}
        <h2 className="new-text">
        cyberpunk meets entertainment
        </h2>
        <h3 className="new-text2">
        Immerse yourself in the thrilling world of a cinematic trans experience, where the unknown awaits, and embark on an unforgettable trip. 
        </h3>
        <h3 className="new-text2">
         </h3>
        <div className="scroll-down">
          Scroll Down
          {downArrowSVG}
        </div>
      </div>
      {/* Other sections */}
      <div className="section content-section">
  <div className="image-container">
    <img
      src={image2}
      alt="Descriptive Alt Text"
      className={`section-image ${isInView ? 'start-animation' : ''}`}
    />
  </div>
  <div className="text-container">
    <h3 className="new-text2">
    Our aim is to craft an unforgettable experience for you. Utilizing AI technology and advanced camera systems, we have developed an immersive environment that allows individuals to intimately connect with the experience of being a receptive partner.    </h3>
    <h3 className="new-text2">
    Experience the intimate thrill of exploring new boundaries and indulging in a close-up perspective that heightens the sense of connection. Discover the allure of sharing intimate moments with a trans woman, embracing the unique experiences that unfold. Allow yourself to be captivated by the journey, including the sensual act of being nourished by her, and so much more.    </h3>
  </div>
</div>

<div className="text-container">
        <h3 className="new-text2">
          In order to ensure your privacy from inquisitive individuals, we provide a range of payment options for your convenience. Alongside traditional methods like credit and debit cards, Paypal, and bank transfers (subject to availability), we also accept cryptocurrency, allowing you to discreetly explore your desires.
        </h3>
        <h3 className="new-text2">
          We will be launching our platform in spring 2024. If you are interested, you can sign up for our email list or join our Telegram channel to stay updated with the latest news and developments. If you are a young and alluring model transmodel who is interested in collaborating with us, please reach out to us.
        </h3> 
      </div>

      <div className="button-container">
        <a href="https://tpov.art" target="_blank" rel="noopener noreferrer" className="custom-button">Sign Up Email</a>
        <a href="https://t.me/tpov_art" target="_blank" rel="noopener noreferrer" className="custom-button">Join Telegram</a>
        <a href="https://tpov.art" target="_blank" rel="noopener noreferrer" className="custom-button">Contact Us</a>
      </div>
    </div>
  );
}

export default App;

