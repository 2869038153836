import React, { useState } from 'react';
import './RollingText.css'; // CSS file for styling

const RollingText = () => {
  const [isVisible, setIsVisible] = useState(true); // State to toggle visibility

  if (!isVisible) return null; // Don't render if not visible

  return (
    <div className="rolling-text-container">
      <button onClick={() => setIsVisible(false)}></button>
      <p>
        We are currently in Mexico, if you are a trans woman actor (professional or amateur, both are welcome) and would like to collaborate with us - please 
        <a href="https://t.me/Random0815Dev" target="_blank" rel="noopener noreferrer"> contact us</a>.
      </p>
    </div>
  );
};

export default RollingText;
